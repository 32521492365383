// =======================================
//   List of variables for Preset color
// =======================================
$brand-color1 : linear-gradient(83.31deg, #1DE9B6 21.22%, #1DC4E9 88.54%);
$brand-color2 : linear-gradient(206.48deg, #A389D4 11.14%, #899ED4 104.6%);
$brand-color3 : linear-gradient(207.92deg, #0398F2 11.42%, #38B9E7 106.55%);
$brand-color4 : linear-gradient(162.57deg, #C5ECF9 11.95%, #C4B4E4 97.32%);

// Gray color
$white: #ffffff;
$gray-100: #F8F9FA;
$gray-200: #F3F5F7;
$gray-300: #DBE0E5;
$gray-400: #BEC8D0;
$gray-500: #8996A4;
$gray-600: #5B6B79;
$gray-700: #3E4853;
$gray-800: #1D2630;
$gray-900: #131920;
$black: #000000;

// blue color
$blue-100: #B4E5FC;
$blue-200: #82D4FA;
$blue-300: #4FC3F8;
$blue-400: #2AB6F7;
$blue-500: #04A9F5;
$blue-600: #03A2F4;
$blue-700: #0398F2;
$blue-800: #028FF0;
$blue-900: #017EEE;

// indigo color
$indigo-100: #d1b7fb;
$indigo-200: #b388f9;
$indigo-300: #9458f6;
$indigo-400: #7d34f4;
$indigo-500: #6610f2;
$indigo-600: #5e0ef0;
$indigo-700: #530cee;
$indigo-800: #4909ec;
$indigo-900: #3805e8;

// purple color
$purple-100: #d1c4e9;
$purple-200: #b39ddb;
$purple-300: #9575cd;
$purple-400: #7e58c2;
$purple-500: #673ab7;
$purple-600: #5f34b0;
$purple-700: #542ca7;
$purple-800: #4a259f;
$purple-900: #391890;

$pink-100: #f8c5dd;
$pink-200: #f49fc6;
$pink-300: #ef78af;
$pink-400: #eb5b9d;
$pink-500: #e83e8c;
$pink-600: #e53884;
$pink-700: #e23079;
$pink-800: #de286f;
$pink-900: #d81b5c;

$red-100: #F99B94;
$red-200: #F88077;
$red-300: #F76E64;
$red-400: #F55247;
$red-500: #F44236;
$red-600: #F21C0D;
$red-700: #DA190C;
$red-800: #C1160B;
$red-900: #A9130A;

$orange-100: #fed8b9;
$orange-200: #febf8a;
$orange-300: #fea55b;
$orange-400: #fd9137;
$orange-500: #fd7e14;
$orange-600: #fd7612;
$orange-700: #fc6b0e;
$orange-800: #fc610b;
$orange-900: #fc4e06;

$yellow-100: #F8DC86;
$yellow-200: #F7D56E;
$yellow-300: #F6CE55;
$yellow-400: #F5C73D;
$yellow-500: #F4C22B;
$yellow-600: #F2B90D;
$yellow-700: #DAA70B;
$yellow-800: #C2950A;
$yellow-900: #AA8209;

$green-100: #BBF8E9;
$green-200: #8EF4DB;
$green-300: #61F0CC;
$green-400: #3FECC1;
$green-500: #1DE9B6;
$green-600: #1AE6AF;
$green-700: #15E3A6;
$green-800: #11DF9E;
$green-900: #0AD98E;

$teal-100: #b3d9d9;
$teal-200: #80c0c0;
$teal-300: #4da6a6;
$teal-400: #269393;
$teal-500: #008080;
$teal-600: #007878;
$teal-700: #006d6d;
$teal-800: #006363;
$teal-900: #005050;

$cyan-100: #C5ECF9;
$cyan-200: #9FDFF5;
$cyan-300: #78D2F0;
$cyan-400: #5BC9ED;
$cyan-500: #3EBFEA;
$cyan-600: #38B9E7;
$cyan-700: #30B1E4;
$cyan-800: #28A9E1;
$cyan-900: #1B9BDB;
